/**
 * Sprites config
 *
 * The key is the name of the sprite, used when rendering and to assign the id.
 * The value is the icon family + component name that would be used when importing
 * from the react-icons library.
 *
 * @example
 * // to replace import { HiLink } from "react-icons/hi2" you would add,
 * const SPRITES = {
 *   ...config,
 *   link: "hi2/HiLink", // <==
 * }
 *
 * // if you need to customize the SVG itself before it's added to the sprite sheet,
 * // you can pass some SVG props instead of just the import path name
 * // to replace import { HiLink } from "react-icons/hi2" you would add,
 * const SPRITES = {
 *   ...config,
 *   link: {
 *     path: "fi/FiChevronDown",
 *     strokeWidth: 2, // <== thicken up the stroke weight a little
 *   }
 * }
 *
 */

export const SPRITES = {
  activity: "fi/FiActivity",
  ai: "ri/RiOpenaiFill",
  alignLeft: "hi2/HiOutlineBars3BottomLeft",
  arrowCirclePath: "hi2/HiOutlineArrowPath",
  arrowDown: "fi/FiArrowDown",
  arrowLeft: "hi2/HiOutlineArrowLeft",
  arrowLeftCircle: "hi2/HiOutlineArrowLeftCircle",
  arrowPathRoundedSquare: "hi2/HiArrowPathRoundedSquare",
  arrowRight: "hi2/HiOutlineArrowRight",
  arrowRightCircle: "hi2/HiOutlineArrowRightCircle",
  arrowRightToLine: "lu/LuArrowRightToLine",
  arrowSmallDown: "hi2/HiOutlineArrowSmallDown",
  arrowSmallUp: "hi2/HiOutlineArrowSmallUp",
  arrowTrendingDown: "hi2/HiOutlineArrowTrendingDown",
  arrowTrendingUp: "hi2/HiOutlineArrowTrendingUp",
  arrowUp: "fi/FiArrowUp",
  arrowUpRightFromBox: "bs/BsBoxArrowUpRight",
  barChart2: "fi/FiBarChart2",
  bell: "hi2/HiOutlineBell",
  bellSnooze: "hi2/HiMiniBellSnooze",
  bulletList: "hi2/HiListBullet",
  calendar: "hi2/HiOutlineCalendarDays",
  calendarSolid: "hi2/HiCalendarDays",
  capsulePill: "bs/BsCapsulePill",
  chat: "hi2/HiOutlineChatBubbleLeftEllipsis",
  chatBubbleWithLines: "hi2/HiOutlineChatBubbleBottomCenterText",
  checkmark: "hi2/HiOutlineCheck",
  checkmarkCircle: "hi2/HiOutlineCheckCircle",
  chevronDown: "hi2/HiOutlineChevronDown",
  chevronLeft: "hi2/HiOutlineChevronLeft",
  chevronRight: "hi2/HiOutlineChevronRight",
  chevronUpDown: "hi2/HiOutlineChevronUpDown",
  circleDot: "tb/TbCircleDot",
  clipboard: "ri/RiClipboardLine",
  clipboardCheck: "hi2/HiOutlineClipboardDocumentCheck",
  clipboardCheck2: "lu/LuClipboardCheck",
  clipboardDocument: "hi2/HiClipboardDocumentList",
  clipboardPlus: "ci/CiMedicalClipboard",
  clock: "hi2/HiOutlineClock",
  codeBracket: "hi2/HiOutlineCodeBracket",
  coffee: "fi/FiCoffee",
  contacts: "ri/RiContactsBook2Line",
  document: "hi2/HiOutlineDocumentText",
  documentCheck: "hi2/HiOutlineDocumentCheck",
  documentPlus: "hi2/HiOutlineDocumentPlus",
  dot: "rx/RxDotFilled",
  draggable: "ri/RiDraggable",
  drink: "md/MdOutlineEmojiFoodBeverage",
  ellipsisHorizontal: "hi2/HiEllipsisHorizontal",
  exclamationCircle: "hi2/HiOutlineExclamationCircle",
  exclamationCircleFilled: "hi/HiExclamationCircle",
  expand: "hi/HiOutlineArrowsExpand",
  externalLink: "fi/FiExternalLink",
  eyeDropper: "hi2/HiEyeDropper",
  file: "fi/FiFile",
  fileAudio: "lu/LuFileAudio",
  fileImage: "lu/LuFileImage",
  filter: "fi/FiFilter",
  fire: "hi2/HiOutlineFire",
  flag: "fi/FiFlag",
  food: "md/MdOutlineFastfood",
  frown: "hi2/HiOutlineFaceFrown",
  googleLogo: "ri/RiGoogleFill",
  hamburger: "hi2/HiOutlineBars3",
  heartHandshake: "lu/LuHeartHandshake",
  help: "fi/FiHelpCircle",
  hiArrowLeft: "hi2/HiOutlineArrowLeft",
  hiArrowLeftCircle: "hi2/HiOutlineArrowLeftCircle",
  hiArrowRight: "hi2/HiOutlineArrowRight",
  hiArrowRightCircle: "hi2/HiOutlineArrowRightCircle",
  hiCheck: "hi2/HiCheck",
  hiClock: "hi2/HiOutlineClock",
  hiCog8Tooth: "hi2/HiOutlineCog8Tooth",
  hiDocumentDuplicate: "hi2/HiOutlineDocumentDuplicate",
  hiMiniClock: "hi2/HiMiniClock",
  hiMiniIdentification: "hi2/HiMiniIdentification",
  hiPlusCircle: "hi2/HiOutlinePlusCircle",
  hiXMark: "hi2/HiXMark",
  home: "fi/FiHome",
  image: "fi/FiImage",
  inboxStack: "hi2/HiOutlineInboxStack",
  info: "fi/FiInfo",
  infoCircle: "bs/BsInfoCircleFill",
  lightning: "hi2/HiOutlineBolt",
  link: "hi2/HiOutlineLink",
  magnifyingGlass: "hi2/HiOutlineMagnifyingGlass",
  mail: "fi/FiMail",
  mdOutlinePersonOff: "md/MdOutlinePersonOff",
  mic: "lu/LuMic",
  micSolid: "hi2/HiMicrophone",
  moon: "hi2/HiOutlineMoon",
  moreVertical: "fi/FiMoreVertical",
  notAllowed: "hi2/HiOutlineNoSymbol",
  note: "md/MdOutlineNoteAlt",
  officeBuilding: "hi2/HiOutlineBuildingOffice2",
  pause: "hi2/HiOutlinePause",
  pencil: "hi2/HiOutlinePencil",
  pencilSolid: "hi2/HiMiniPencil",
  pencilSquare: "hi2/HiMiniPencilSquare",
  phone: "hi2/HiOutlinePhone",
  pin: "hi2/HiOutlineMapPin",
  plus: "hi2/HiOutlinePlus",
  plusCircle: "hi2/HiOutlinePlusCircle",
  prescription: "bs/BsCapsulePill",
  queue: "hi2/HiQueueList",
  queueOutline: "hi2/HiOutlineQueueList",
  repeat: "fi/FiRepeat",
  reset: "rx/RxReset",
  rotateCcw: "fi/FiRotateCcw",
  sortDown: "bi/BiSortDown",
  sortUp: "bi/BiSortUp",
  soundWave: "bs/BsSoundwave",
  sparkles: "hi2/HiSparkles",
  spinner: "cg/CgSpinner",
  star: "hi2/HiStar",
  stethoscope: "ri/RiStethoscopeFill",
  sun: "hi2/HiOutlineSun",
  testTubeDiagonal: "bi/BiTestTube",
  textSelect: "lu/LuTextSelect",
  todoList: "lu/LuListTodo",
  trash: "hi2/HiOutlineTrash",
  truck: "fi/FiTruck",
  user: "hi2/HiOutlineUser",
  userCircle: "hi2/HiOutlineUserCircle",
  userPlus: "fi/FiUserPlus",
  users: "hi2/HiOutlineUsers",
  video: "hi2/HiOutlineVideoCamera",
  visitNotes: "cg/CgNotes",
  warningTriangle: "hi2/HiOutlineExclamationTriangle",
  warningTriangleSolid: "hi2/HiExclamationTriangle",
  wrenchScrewdriver: "hi2/HiOutlineWrenchScrewdriver",
  x: "hi2/HiOutlineXMark",
  xCircle: "hi2/HiOutlineXCircle",
  xCircleSolid: "hi2/HiXCircle",
} as const satisfies SpriteMap;

export type SpriteName = keyof typeof SPRITES;

// try to help guide us into the family/name path pattern
// that react-icons uses
//
// to give some assistance, I'm listing some of the icon family
// prefixes that are known to fit the viewBox properly
type MakeReactIconPathPrefix<
  Family extends string,
  IconPrefix extends string = Family,
> = `${Family}/${Capitalize<IconPrefix>}${string}`;

// NOTE: "pi" is not compatible with our viewBox stuff, avoid if possible
type ValidReactIconPathPrefix =
  | MakeReactIconPathPrefix<"bi">
  | MakeReactIconPathPrefix<"bs">
  | MakeReactIconPathPrefix<"ci">
  | MakeReactIconPathPrefix<"cg">
  | MakeReactIconPathPrefix<"fi">
  | MakeReactIconPathPrefix<"hi">
  | MakeReactIconPathPrefix<"hi2", "Hi">
  | MakeReactIconPathPrefix<"md", "MdOutline"> // prefer outline versions
  | MakeReactIconPathPrefix<"lu">
  | MakeReactIconPathPrefix<"ri">
  | MakeReactIconPathPrefix<"rx">
  | MakeReactIconPathPrefix<"tb">;

export type ReactIconsPath = `${ValidReactIconPathPrefix}${string}`;

// height, width are not configurable via props - they are computed during generation by genFile/IconBase OR taken from an react-icons#IconContext.Provider
export type SpriteConfig = {
  path: ReactIconsPath;
} & Omit<JSX.IntrinsicElements["svg"], "height" | "width" | "xmlns">;

export type SpriteMap = Record<string, ReactIconsPath | SpriteConfig>;
